import { gql } from "@apollo/client";

const CANCEL_SUBSCRIPTION = gql`
    mutation CANCEL_SUBSCRIPTION($input: CancelSubscriptionInput!) {
      cancelSubscription(input: $input) {
        node{
          products {
            id_product
            name
            link
            price
            total_price
            quantity
          }
          
          order_id
          active
          start_payment
          next_payment
          end_payment
          status_payment
          total_price
          active
          shipping_name
          shipping_total
          shipping_address
        }
      }
    }
`;

export default CANCEL_SUBSCRIPTION;