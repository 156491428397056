import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import GET_CUSTOMER_ORDER from "../../../queries/get-customer-orders"
import { isEmpty } from "lodash"
import Spin from "../../spin/Spin"
import "./style.scss"
import { Link } from "gatsby"
import { removeHost } from "../../../utils/functions"
import CANCEL_SUBSCRIPTION from "../../../mutations/cancel_subscription"

const Subscription = props => {
  // console.log(props)
  const id = props.authData.user.databaseId
  const {
    title,
    noSubscription,
    productHeading,
    productName,
    productPrice,
    nextPaymentDateText,
    productQuantity,
    productTotalPrice,
    shippingAddressHeading,
    shippingHeading,
    shippingName,
    shippingPrice,
    startDateText,
    statusActiveText,
    statusStoppedText,
    statusText,
    totalText,
    actionText,
    actionCancelText,
  } = props.subscription

  const [loading, setLoading] = useState(true)
  const [loadingAction, setLoadingAction] = useState(false)

  const [subscription, setSubscription] = useState(true)
  // Get Cart Data.

  useQuery(GET_CUSTOMER_ORDER, {
    variables: {
      customerId: id,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: data => {
      setLoading(false)
      setSubscription(data.customer.subscriptionItems)
    },
    onError: errors => {},
  })

  const [cancelSubscription, {}] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted: (data) => {
      setSubscription(data.cancelSubscription.node)
      setLoadingAction(false)
    },
    onError: errors => {
      if (errors) {
        setLoadingAction(false)
      }
    },
  })

  return (
    <>
      <h1>{title}</h1>
      <div className="content">
        {loading ? (
          <div className="myAccount__spin">
            <Spin />
          </div>
        ) : (
          <>
            {!isEmpty(subscription) ? (
              <div className="myAccountSubscription">
                {subscription.map((subscriptionSingle, index2) => {
                  console.log(subscriptionSingle.next_payment)
                  return (
                    <div
                      className="myAccountSubscription__single"
                      key={subscriptionSingle.order_id}
                      id={subscriptionSingle.order_id}
                    >
                      <div className="myAccountSubscription__single__header">
                        <div className="myAccountSubscription__single__header__row">
                          <div className="myAccountSubscription__single__header__col">
                            <div className="myAccountSubscription__single__header__row">
                              <p className="myAccountSubscription__single__header__col">
                                {startDateText}
                              </p>
                              <p className="myAccountSubscription__single__header__col">
                                {subscriptionSingle.start_payment}
                              </p>
                            </div>
                            <div className="myAccountSubscription__single__header__row">
                              <p className="myAccountSubscription__single__header__col">
                                {nextPaymentDateText}
                              </p>
                              <p className="myAccountSubscription__single__header__col">
                                {subscriptionSingle.next_payment}
                              </p>
                            </div>
                            <div className="myAccountSubscription__single__header__row">
                              <p className="myAccountSubscription__single__header__col">
                                {statusText}
                              </p>
                              <p className="myAccountSubscription__single__header__col">
                                {subscriptionSingle.active
                                  ? statusActiveText
                                  : statusStoppedText}
                              </p>
                            </div>
                            {subscriptionSingle.next_payment !== "-" ? (
                              <div className="myAccountSubscription__single__header__row myAccountSubscription__single__header__row--action">
                                <p className="myAccountSubscription__single__header__col ">
                                  {actionText}
                                </p>
                                <div>
                                  <a
                                    className="btn"
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault()
                                      cancelSubscription({
                                        variables: {
                                          input:{
                                            user_id: ''+id,
                                            order_id: subscriptionSingle.order_id,
                                          }
                                        },
                                      })
                                    }}
                                  >
                                    {actionCancelText}
                                  </a>
                                  {loadingAction && <Spin />}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="myAccountSubscription__single__header__col myAccountSubscription__single__header__col--shipping">
                            <h3>{shippingAddressHeading}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: subscriptionSingle.shipping_address,
                              }}
                            ></p>
                          </div>
                        </div>
                        {}
                      </div>
                      <div className="myAccountSubscription__single__data">
                        {subscriptionSingle?.products ? (
                          <>
                            <h3>{productHeading}</h3>
                            <div className="myAccountSubscription__single__tabel">
                              <div className="myAccountSubscription__single__tabel__header">
                                <div className="myAccountSubscription__single__row">
                                  <div className="myAccountSubscription__single__col myAccountSubscription__single__col--name">
                                    {productName}
                                  </div>
                                  <div className="myAccountSubscription__single__col myAccountSubscription__single__col--price">
                                    {productPrice}
                                  </div>
                                  <div className="myAccountSubscription__single__col myAccountSubscription__single__col--quantity">
                                    {productQuantity}
                                  </div>
                                  <div className="myAccountSubscription__single__col myAccountSubscription__single__col--totalPrice">
                                    {productTotalPrice}
                                  </div>
                                </div>
                              </div>
                              {subscriptionSingle?.products.map(
                                (product, index) => (
                                  <div
                                    className="myAccountSubscription__single__row"
                                    id={`subscriptionSingle__${subscriptionSingle.order_id}__${product.id_product}`}
                                    key={`subscriptionSingle__${subscriptionSingle.order_id}__${product.id_product}`}
                                  >
                                    <div className="myAccountSubscription__single__col myAccountSubscription__single__col--name">
                                      <Link to={removeHost(product.link)}>
                                        {product.name}
                                      </Link>
                                    </div>
                                    <div className="myAccountSubscription__single__col myAccountSubscription__single__col--price">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: product.price,
                                        }}
                                      />
                                    </div>
                                    <div className="myAccountSubscription__single__col myAccountSubscription__single__col--quantity">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: product.quantity,
                                        }}
                                      />
                                    </div>
                                    <div className="myAccountSubscription__single__col myAccountSubscription__single__col--totalPrice">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: product.total_price,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {subscriptionSingle?.shipping_name ? (
                          <>
                            <h3>{shippingHeading}</h3>
                            <div className="myAccountSubscription__single__tabel">
                              <div className="myAccountSubscription__single__tabel__header">
                                <div className="myAccountSubscription__single__row">
                                  <div className="myAccountSubscription__single__col myAccountSubscription__single__col--name">
                                    {shippingName}
                                  </div>
                                  <div className="myAccountSubscription__single__col myAccountSubscription__single__col--totalPrice">
                                    {shippingPrice}
                                  </div>
                                </div>
                              </div>
                              <div className="myAccountSubscription__single__row">
                                <div className="myAccountSubscription__single__col myAccountSubscription__single__col--name">
                                  <span></span>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: subscriptionSingle.shipping_name,
                                    }}
                                  />
                                </div>
                                <div className="myAccountSubscription__single__col myAccountSubscription__single__col--totalPrice">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: subscriptionSingle.shipping_total,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <h3 className="myAccountSubscription__single__total">
                          {totalText}:{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: subscriptionSingle.total_price,
                            }}
                          />
                        </h3>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="content">
                <div dangerouslySetInnerHTML={{ __html: noSubscription }}></div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Subscription
